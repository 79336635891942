html {
  font-family: "Roboto", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Helvetica";
}

.MuiButton-sizeLarge {
  padding: 12px !important;
  font-weight: bold;
}

.MuiInputLabel-shrink {
  background-color: transparent;
  padding: 0 5px;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff3f6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

:root {
  --main-color: #00ca8d;
}

.mb-10 {
  margin-bottom: 10px;
}

.container {
  padding: 15px;
}

.item {
  background-color: rgba(62, 62, 62, 0.05);
  border: 1px solid rgba(62, 62, 62, 0.2);
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.MuiPaper-elevation3 {
  /* box-shadow: 0px 0px 18px #e6ebf3 !important; */
}
